import axios from "axios";

const origin = 'https://dashboard.greenpandagames.com/website-admin';

const sendFormData = async (url, data, token) => {
    
    const postData = {
        ...data,
        captcha: token
    }

    const response = await axios.post(url, postData);

    if (response.error) {
        throw new Error(response.error.message)
    }

    return true;
}

const sendPublishingData = async (data, token) => {
   const requestURL = origin + "/submit-your-game-form?key=51d4dd351b270114bd89e0cb234bcb96";
    
   return await sendFormData(requestURL, data, token);
}

const sendSupportData = async (data, token) => {
    const requestURL = origin + "/contact-us-form?key=51d4dd351b270114bd89e0cb234bcb96";

    return await sendFormData(requestURL, data, token);

}


export {
    sendPublishingData,
    sendSupportData
}