const Routes = {
    home: "/",
    career: "/jobs",
    games: "/games",
    support: "/support",
    submit: "/publishing",


    facebook: "https://www.facebook.com/GreenPandaGames1/",
    linkedin: "https://www.linkedin.com/company/green-panda-games/",
    instagram: "https://www.instagram.com/greenpandagame/",

    more_games: "https://apps.apple.com/developer/green-panda-games/id669978473",

    terms: "https://legal.ubi.com/termsofuse/en-INTL",
    privacy: "https://legal.ubi.com/privacypolicy/en-INTL",

    press_kit: "/",
}

export default Routes;