import { createContext, useCallback, useContext, useMemo, useState } from "react";
import { CookiesModal } from "./CookiesModal";
import { Modal } from "./Modal";

export const MODAL_TYPES = {
    COOKIE_MODAL: "COOKIE_MODAL",
};

const MODAL_COMPONENTS = {
    [MODAL_TYPES.COOKIE_MODAL]: CookiesModal,
    // [MODAL_TYPES.DELETE_MODAL]: DeleteModal,
    // [MODAL_TYPES.UPDATE_MODAL]: UpdateModal
};

const initalState = {
    showModal: () => {},
    hideModal: () => {},
    store: {},
};

const ModalContext = createContext(initalState);

export const useGlobalModalContext = () => useContext(ModalContext);

// let timeout = null;

const disabledBodyScroll = () => {
    document.body.style.paddingRight = (window.innerWidth - document.body.clientWidth) + "px";
    document.body.style.overflow = "hidden";
}

const enableBodyScroll = () => {
    document.body.style.cssText = "";
}

export const GlobalModal = ({ children }) => {
    const [store, setStore] = useState({});


    const [animation, setAnimation] = useState("hide d-none");

    const { modalType, modalProps } = store;


   
    const showModal = useCallback((modalType, modalProps = {}, options = {}) => {

        setAnimation("d-block");

        disabledBodyScroll();

        // clearTimeout(timeout)
        setTimeout(() => setAnimation("d-block show"), 0)

        // setStore({
        //     ...store,
        //     modalType,
        //     modalProps,
        //     options
        // });
        setStore(s => ({
            ...s,
            modalType,
            modalProps,
            options
        }));

    }, []);
   
    const hideModal =  useCallback(() => {
        setAnimation("d-block hide");
        enableBodyScroll();
        // clearTimeout(timeout)
        setTimeout(() => {
            setAnimation("d-none hide");


            // setStore({
            //     ...store,
            //     modalType: null,
            //     modalProps: {},
            // });

            setStore(s => ({
                ...s,
                modalType: null,
                modalProps: {},
                options: {}
            }));
        }, 150)
    }, []);
    const contextValue = useMemo(() => ({showModal, hideModal}), [showModal, hideModal])

    const ModalComponent = MODAL_COMPONENTS[modalType];
    const isModalHidden = !modalType || !ModalComponent;
   
    const renderComponent = () => {
      if (isModalHidden) {
        return null;
      }
      return <Modal classes={animation} hideModal={store.options.lock ? () => {} : hideModal}>
          <ModalComponent id="global-modal" {...modalProps} />
        </Modal>;
    };
   
    return (
      <ModalContext.Provider value={contextValue}>
        {renderComponent()}
        <div className={"modal-backdrop fade " + animation} onScroll={(e) => e.preventDefault()}></div>
        {children}
      </ModalContext.Provider>
    );
};