/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

 import { useContext, useLayoutEffect } from 'react';
 import { localeContext } from '../locales';
 
 import GAMES from '../consts/Games';
 
 import FooterCareers from '../partials/FooterCareers';
 import { GameCard } from '../components';
 import Routes from '../consts/Routes';

 const DISPLAY_GAMES = [
    "game_ai",
    "game_bf",
    "game_sb",
    "game_mi",
    "game_ic",
    "game_ih",
    "game_tr",
    "game_9m",
]

 const Games = (props) => {
 
     const texts = useContext(localeContext);
 
     const gamesByKey = GAMES.reduce((prev, val) => {
		prev[val.key] = val;
		return prev;
	}, {})


    useLayoutEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "instant"
        })
    });

	const gamesData = DISPLAY_GAMES.map(key => gamesByKey[key]);

     return (
         <div className='games-page'>
             <div className='games-header page-header d-flex flex-column'>

                 <div className='mx-auto d-flex flex-column'>
                     <div className='title font-header text-center mx-auto'>{texts.games_title}</div>
                     <div className='subtitle'>{texts.games_subtitle}</div>
                 </div>

             </div>
            <div className='games-content'>
                <div className='title font-header'>
                    {texts.games_page_title}
                </div>
                <div className='games-list d-flex flex-wrap'>
                    {
						gamesData.map(gameData =>

							<div key={gameData.key} className='col-6 col-md-3 col-lg-3 d-flex justify-content-center game-card'>
								<GameCard 
									title={texts[gameData.key]} 
									image={gameData.thumbnail}
									appstore_link={gameData.appstore}
									playmarket_link={gameData.playmarket}
									subtitle={texts.game_available}
								/>
							</div>
						)
					}
                </div>
                <a href={Routes.more_games} target="_blank" rel="noreferrer" className='games-btn btn font-header'>
                    {texts.games_more}
                </a>
                
            </div>
             <FooterCareers/>
         </div>
     );
 
 }
 
 export default Games;