import ReCAPTCHA from "react-google-recaptcha";
import config from "../config";

const CaptchaForm = ({texts, onSubmit}) => {

    return <form>
        <div className='title font-header'>
            {texts.submit_verify}
        </div>

        <ReCAPTCHA
            sitekey={config.sitekey}
            onChange={(token) => {onSubmit(token) }}
        />
    </form>
    
}


export { CaptchaForm };