import { useContext } from "react"
import { localeContext } from "../locales"



const BENEFITS_KEYS = [
    "grow",
    "progress",
    "company",
    "sports",
    "others",
]

const FooterBenefits = () => {

    const texts = useContext(localeContext)

    return <>
        <div id="benefits" className='footer-benefits d-flex flex-wrap'>
            
            <div className="col-12 col-lg-4 cell cell-header">
                <div className="title font-header">{texts.benefits_title}</div>
            </div>
            {
                BENEFITS_KEYS.map(key => (
                    <div key={key} className="col-12 col-lg-4 cell">
                        <i className={"icon icon-" + key}/>
                        <div className="font-header title">
                            {texts["benefits_" + key + "_title"]}
                        </div>
                        <div className="text">
                            {texts["benefits_" + key + "_text"]}
                        </div>
                    </div>
                ))
            }



        </div>
    </>
}

export default FooterBenefits