import { useContext } from "react"
import { localeContext } from "../locales"

import careerImg from '../img/footer/careers.png';
import { Link } from "react-router-dom";
import Routes from "../consts/Routes";

const FooterCareers = () => {

    const texts = useContext(localeContext)
    return <>
        <div className='footer-adventure'>
            <div className='d-flex flex-wrap'>
                <div className='col-12 col-lg-5'>
                    <div className='image-background-sm'>
                    </div>
                    <div className='image-background'>
                        <img src={careerImg} width={300} height={300} alt="careers"></img>
                    </div>
                </div>
                <div className='col-12 col-lg-7'>
                    <div className='text-card'>
                        <div className='title font-header'>{texts.footer_adventure_title}</div>
                        <div className='text'>{texts.footer_adventure_subtitle}</div>
                        <Link to={Routes.career}>
                            <div className='btn font-header'>{texts.footer_adventure_button}</div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default FooterCareers