
const config = {

	admin_url: 'https://admin.greenpandagames.com/',

	s3_url: 'https://static.greenpandagames.com/',

	organization_reference: '8Vgz2M1',

	sitekey: '6LdvsDcgAAAAAFPnh1BR88NSx9254wiJyk9fheER'

};

// if (process.env.NODE_ENV === "development") {
// 	config.sitekey = "6LeH4wUgAAAAAJCDBDXnsZh3x-53hIKDALfFaLwp"
// }

export default config;