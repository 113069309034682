const GameCard = (props) => {

	const { title, image, appstore_link, playmarket_link, subtitle } = props;

	return (
		<>
			<div className='d-flex flex-column game'>
				<img className='thumbnail' width={190} height={283} src={image} alt={title}/>
				<div className='title font-header'>{title}</div>
				<div className='subtitle'>{subtitle}</div>
				<div className='d-flex links'>
					<a className='link icon icon-playmarket' target="_blank" rel="noreferrer" href={playmarket_link}>
						<i className="fas fa-link" aria-hidden="true"></i>
					</a>
					<a className='link icon icon-appstore' target="_blank" rel="noreferrer" href={appstore_link}>
						<i className="fas fa-link" aria-hidden="true"></i>
					</a>
				</div>
			</div>
		</>
	)
}

export { GameCard };