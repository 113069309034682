// import axios from "axios";

// const gamesCache = {};

// const requestURL = "https://admin.greenpandagames.com/rest/game-order?locale=";

const getGames = (locale) => {
    // locale = locale || 'en';

    // if (gamesCache[locale])
    // {
    //     return Promise.resolve(gamesCache[locale]);
    // }

    // return axios.post(requestURL + locale).then((data) => {
    //     gamesCache[locale] = data;

    //     console.log(data.map((item) => ({name: item.name})));
    //     return data;
    // });

    /**
     * Version 2.0 
     */
    return Promise.resolve([
        { name: 'Idle Airplane Inc. Tycoon' },
        { name: 'Idle Space Station - Tycoon' },
        { name: 'Idle 9 Months' },
        { name: 'Bee Factory' },
        { name: 'Sushi Bar Idle' },
        { name: 'Terrarium: Garden Idle' },
        { name: 'Idle Human' },
        { name: 'Idle Construction 3D' },
        { name: 'Gun Idle' },
        { name: 'Weed Factory Idle' },
        { name: 'Idle Slice and Dice' },
        { name: 'Mining Inc.' },
        { name: 'Idle Island Inc' },
        { name: 'Car Wash Empire' },
        { name: 'Emoji Craft' },
        { name: 'Idle AquaPark' },
        { name: 'Idle Workout !' },
        { name: 'Magic Mushrooms' }
    ]);

    /**
     * Version 1.0 
     */
    // return Promise.resolve([
    //     {name: 'Idle Human'},
    //     {name: 'Idle Construction 3D'},
    //     {name: 'Sushi Bar Idle'},
    //     {name: 'Bee Factory'},
    //     {name: 'Gun Idle'},
    //     {name: 'Idle 9 Months'},
    //     {name: 'Idle Island Inc'},
    //     {name: 'Terrarium: Garden Idle'},
    //     {name: 'Emoji Craft'},
    //     {name: 'Idle Workout !'},
    //     {name: 'Magic Mushrooms'},
    //     {name: 'Weed Factory Idle'},
    //     {name: 'Car Wash Empire'},
    //     {name: 'Idle AquaPark'},
    //     {name: 'Golf Orbit'},
    //     {name: 'Golf Inc. Tycoon'},
    //     {name: 'Mining Inc.' },
    //     {name: 'Farm Factory' },
    //     {name: 'Idle Slice and Dice'},
    //     {name: 'Wool Factory Idle'},
    //     {name: 'Solitaire'}
    // ])
};

export { getGames };
