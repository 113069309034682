import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Routes from "../consts/Routes";

const FormSubmitted = ({texts}) => {

    const history = useHistory();

    useEffect(() => {

        const timer = setTimeout(() => {
            history.push(Routes.home);
        }, 5000)


        return () => {
            clearTimeout(timer)
        }

    }, [history])

    return <>
        <div className='title font-header text-center p-5 m-5'>
            <p>{texts.submit_ty}</p>
            <p>{texts.submit_submitted}</p>
        </div>
    </>
}

export { FormSubmitted };