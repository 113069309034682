/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import React from 'react';

import {
  Router,
  Switch,
  Route
} from 'react-router-dom';

import { createBrowserHistory } from 'history';

import utils from './utils';

import Topbar from './partials/Topbar';
import Footer from './partials/Footer';


import HomePage from './pages/Home';
import CareerPage from './pages/Career';
import GamesPage from './pages/Games';
import SupportPage from './pages/Support';
import SubmitPage from './pages/Submit';
import LegalPage from './pages/Legal';


import { locales, localeContext } from './locales';
import Routes from './consts/Routes';
import { GlobalModal } from './modals/ModalContext';
import { CheckCookies } from './components/CheckCookies';

const browserLanguage = utils.getLanguage();

const history = createBrowserHistory();

history.listen((location) => 
{
    window.gpage(location.pathname);
});

class App extends React.Component 
{
    constructor(props) 
    {
        super(props);

        this.state = {
            locale: locales[browserLanguage] || locales['en']
        };

        this.changeLocale = this.changeLocale.bind(this);
    }

    changeLocale(locale) 
    {
        this.setState({locale: locales[locale] || locales['en']});
    }

    componentWillUnmount() 
    {
        
    }

    componentDidMount() 
    {
        
    }

    componentDidUpdate(prevProps, prevState, snapshot) 
    {

    }

    render() 
    {
        return (
            <Router history={history}>

                <localeContext.Provider value={this.state.locale}>
                    <GlobalModal>
                    <Topbar />

                    <div className='main'>

                        <Switch>

                            {/* <Route exact path="/blank" component={BlankPage} /> */}

                            <Route exact path={Routes.submit} component={SubmitPage} />
                            <Route exact path={Routes.support} component={SupportPage} />

                            {/**
                             * TO DO: remove it later, temporary fix for backward compatibility with old environment
                             * All our games redirect exact to https://greenpandagames.com/en/support
                             */}
                            <Route exact path="/en/support" component={SupportPage} />

                            <Route exact path={Routes.games} component={GamesPage} />
                            <Route exact path={Routes.career} component={CareerPage} />

                            <Route exact path={Routes.home} component={HomePage} />

                            <Route path="/en/:page" component={LegalPage} />
                            <Route path="/fr/:page" component={LegalPage} />

                            <Route path="*" component={HomePage} />

                        </Switch>

                        <Footer changeLocale={this.changeLocale} />

                    </div>
                    <CheckCookies/>

                    {/* <Modals /> */}
                </GlobalModal>
                </localeContext.Provider>

            </Router>
        );
    }
}

export default App;