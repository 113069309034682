import mi_thumbnail from '../img/games/mininginc@1x.png';
import bf_thumbnail from '../img/games/beefactory@1x.png';
import sb_thumbnail from '../img/games/sushibar@1x.png';
import ii_thumbnail from '../img/games/islandinc@1x.png';

import ih_thumbnail from '../img/games/idle_human@1x.png';
import ic_thumbnail from '../img/games/idleconstruction@1x.png';
import tr_thumbnail from '../img/games/terrarium@1x.png';
import nm_thumbnail from '../img/games/9month@1x.png';

import ai_thumbnail from '../img/games/airplaneinc.jpg';

const GAMES = [
	{
		key: "game_ai",
		thumbnail: ai_thumbnail,
		appstore: "https://apps.apple.com/app/id6445961774",
		playmarket: "https://play.google.com/store/apps/details?id=com.hb.airplaneinc",
	},
	{
		key: "game_mi",
		thumbnail: mi_thumbnail,
		appstore: "https://apps.apple.com/app/mining-inc/id1507179024",
		playmarket: "https://play.google.com/store/apps/details?id=com.gpp.mininginc",
	},
	{
		key: "game_bf",
		thumbnail: bf_thumbnail,
		appstore: "https://apps.apple.com/app/bee-factory/id1411267916",
		playmarket: "https://play.google.com/store/apps/details?id=com.gpp.beefactory",
	},
	{
		key: "game_sb",
		thumbnail: sb_thumbnail,
		appstore: "https://apps.apple.com/app/sushi-bar-idle/id1438089337",
		playmarket: "https://play.google.com/store/apps/details?id=com.gpp.sushibar",
	},
	{
		key: "game_ii",
		thumbnail: ii_thumbnail,
		appstore: "https://apps.apple.com/app/idle-island-inc/id1561333279",
		playmarket: "https://play.google.com/store/apps/details?id=com.gpp.islandinc",
	},
	{
		key: "game_9m",
		thumbnail: nm_thumbnail,
		appstore: "https://apps.apple.com/app/idle-9-months/id1497263321",
		playmarket: "https://play.google.com/store/apps/details?id=com.gpg.ninemonths",
	},
	{
		key: "game_ih",
		thumbnail: ih_thumbnail,
		appstore: "https://apps.apple.com/us/app/idle-human/id1466582065",
		playmarket: "https://play.google.com/store/apps/details?id=com.idle.idleHuman",
	},
	{
		key: "game_ic",
		thumbnail: ic_thumbnail,
		appstore: "https://apps.apple.com/app/idle-construction-3d/id1473951944",
		playmarket: "https://play.google.com/store/apps/details?id=com.gpp.idleconstruction",
	},
	{
		key: "game_tr",
		thumbnail: tr_thumbnail,
		appstore: "https://apps.apple.com/app/terrarium-garden-idle/id1401837582",
		playmarket: "https://play.google.com/store/apps/details?id=sk.phx.terrarium",
	},

]

export default GAMES;