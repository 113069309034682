/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import React, { useContext, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Routes from '../consts/Routes';
import logo from '../img/topbar/logo.svg';
import { localeContext } from '../locales';

const topbarLinks = [
    {
        key: 'COMPANY',
        to: Routes.home,
        classes: 'topbar-link'
    },
    {
        key: 'OUR_GAMES',
        to: Routes.games,
        classes: 'topbar-link'
    },
    {
        key: 'SUPPORT',
        href: 'mailto:support@greenpandagames.com',
        classes: 'topbar-link'
    },
    {
        key: 'SUBMIT_YOUR_GAME',
        to: Routes.submit,
        classes: 'topbar-link'
    },
    {
        key: 'JOIN_US',
        to: Routes.career,
        classes: 'topbar-btn'
    }
];

const Topbar = (props) => {
    const texts = useContext(localeContext);

    const btn = useRef(null);

    useEffect(() => {
        const onClick = (e) => {
            if (btn.current.clientHeight) {
                const path = [];

                let target = e.target;

                while (target.parentNode) {
                    path.push(target);
                    target = target.parentNode;
                }

                if (!path.some((el) => el instanceof HTMLElement && el.classList.contains('navbar')))
                    !btn.current.classList.contains('collapsed') && btn.current.click();
            }
        };

        document.body.addEventListener('pointerdown', onClick);

        return () => {
            document.body.removeEventListener('pointerdown', onClick);
        };
    }, []);

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light shadow-sm sticky-top app-topbar">
            <div className="container-fluid">
                <Link className="" to={Routes.home}>
                    <img src={logo} alt="Company logo" width={210} height={39} />
                </Link>
                <button
                    ref={btn}
                    className="navbar-toggler collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNavAltMarkup"
                    aria-controls="navbarNavAltMarkup"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                    <div className="navbar-nav ms-auto">
                        {topbarLinks.map((link) => {
                            return link.href ? (
                                <a key={link.key} className={'nav-link ' + link.classes} href={link.href}>
                                    {texts[link.key]}
                                </a>
                            ) : (
                                <Link
                                    onClick={() => btn.current.clientHeight && btn.current.click()}
                                    key={link.key}
                                    className={'nav-link ' + link.classes}
                                    to={link.to}
                                >
                                    {texts[link.key]}
                                </Link>
                            );
                        })}
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Topbar;
