import { useEffect } from "react";
import { getCookies } from "../api/cookies";
import { MODAL_TYPES, useGlobalModalContext } from "../modals/ModalContext";

const CheckCookies = () => {
    const { showModal } = useGlobalModalContext();
    
    
    useEffect(() => {
    	const cookies = getCookies();

        if (typeof cookies["accept_cookie_policy"] === "undefined") {
            showModal(MODAL_TYPES.COOKIE_MODAL, {}, {lock: true});
        }
    }, [showModal])

    return null;
}

export { CheckCookies };