/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import React from 'react';
import en from './en';

const locales = {
	en,
	// ru
}

const localeContext = React.createContext(locales.en);

export { locales }

export { localeContext }