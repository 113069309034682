/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import axios from 'axios';
import { CountriesObj } from '../consts/Countries';

const jobsCache = {};

const requestURL = "https://api.smartrecruiters.com/v1/companies/Ubisoft2/postings?custom_field.56c253c2e4b0cabf01d19d37=f8417fb7-e80b-4933-b36e-6b83791cca8a";

const jobURL = "https://www.smartrecruiters.com/";

const getJobs = (locale) => 
{
    locale = locale || 'en';

    if (jobsCache[locale]) 
    {
        return Promise.resolve(jobsCache[locale]);
    }

    return axios.get(requestURL).then((data) => {

        const jobsData = data.content.map(item => ({
            name: item.name.replace(/\s*-\s*Green Panda Games/, ""),
            to: jobURL + item.company.identifier + "/" + item.id,
            id: item.id,
            city: item.location.city + ", " + CountriesObj[item.location.country.toUpperCase()]
        }))

        jobsCache[locale] = jobsData;
        return jobsData;
    });
}

export { getJobs };