import Cookies from 'js-cookie';


const cookie_types = {
    // "cookie_analytics_track": (val) => val === "true",
    "accept_cookie_policy": (val) => val === "true",
}

const getCookies = () => {

    const data = Cookies.get();


    for (let key in data) {
        if (cookie_types[key]) {
            data[key] = cookie_types[key](data[key]);
        }
    }
    

    return data;
}

const setCookies = (data = {}) => {

    // if (data["cookie_analytics_track"] === true) 
    // {
    //     window.ginit();
    // }
    // else if (data["cookie_analytics_track"] === false)
    // {
    //     window.gtag = function(){};
    //     Cookies.remove('_ga');
    //     Cookies.remove('_gid');
    //     Cookies.remove('_gat');
    //     Cookies.remove('_gat_gtag_' + window.gtagId.replaceAll('-', '_'));
    // }

    for (let key in data) {
        Cookies.set(key, data[key], { expires: 7 })
    }
}

// const cookies = getCookies();

// if (cookies["cookie_analytics_track"]) 
// {
//     window.ginit();
// }

export {
    getCookies,
    setCookies
}