
import { CountriesObj, Languages } from '../consts';

function betweenCaseSensitive(str1, str2)
{
    return new RegExp("(?:" + str1 + ")(.*?)(?:" + str2 + ")", "g");
}

function betweenCaseInsensitive(str1, str2)
{
    return new RegExp("(?:" + str1 + ")(.*?)(?:" + str2 + ")", "ig");
}

function getFirstBrowserLanguage() 
{
    var nav = window.navigator,
        browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'],
        i,
        language;

    if (Array.isArray(nav.languages)) {
        for (i = 0; i < nav.languages.length; i++) {
            language = nav.languages[i];
            if (language && language.length) {
                return language;
            }
        }
    }

    for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
        language = nav[browserLanguagePropertyKeys[i]];
        if (language && language.length) {
            return language;
        }
    }

    return "null";
};

const Utils = 
{
    countryName: function(code) 
    {
        return CountriesObj[code] || code;
    },

    languageName: function(code) 
    {
        return Languages[code] || code;
    },

    countryFlag: function(code) 
    {
        return 'https://cdn.jsdelivr.net/gh/hjnilsson/country-flags@latest/svg/' + code.toLowerCase() + '.svg';
    },

    is: function(percent) 
    {
        percent = percent || 0.5;

        return Math.random() < percent;
    },

    remove: function(arr, predicate) 
    {
        const index = arr.findIndex( predicate );

        if (index > -1) return arr.splice(index, 1)[0];

        return [];
    },

    clone: function(obj) 
    {
        return JSON.parse(JSON.stringify(obj));
    },

    fileName: function(path, keepExtension) 
    {
        var res = path.substring(path.lastIndexOf('/')+1);
        
        if (!keepExtension) 
        {
            res = res.split('.');
            res.length > 1 && res.pop();
            res = res.join('.');
        }

        return res;
    },

    formatDate: function(_d, format) 
    {
        let dd = _d.getDate();
        let mm = _d.getMonth() + 1; 
        let yyyy = _d.getFullYear();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        return format.replace('yyyy', yyyy).replace('mm', mm).replace('dd', dd);
    },

	inputToNum: function(e) 
	{
		return {
			target: {
				name: e.target.name, 
				value: e.target.value ? Number(e.target.value) : void 0
			}
		}
	},

	el: function(id) 
	{
		return document.getElementById(id);
	},

	ev: function(id) 
	{
		return Utils.el(id).value;
	},


	substr2: function(str, str1, str2) 
    {
        let test = betweenCaseInsensitive(str1, str2).exec(str);

        if (test && test.length > 1)
        {
            return test[1];
        }

        return null;
    },

    substr: function(str, str1, str2) 
    {
        let test = betweenCaseSensitive(str1, str2).exec(str);

        if (test && test.length > 1)
        {
            return test[1];
        }

        return null;
    },

    pushLimit: function(array, element, limit, front) 
    {
        if (front === true) 
        {
            array.splice(0, 0, element);
            array.splice(limit, array.length - limit);
        }
        else 
        {
            array.push(element);
            array.splice(0, array.length - limit);
        }
    },

    getLanguage: function(fullName) 
    {
        if (fullName) 
        {
            return getFirstBrowserLanguage()
        }
        else 
        {
            return getFirstBrowserLanguage().slice(0, 2);
        }
    }
}

export default Utils;