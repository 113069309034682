/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { getJobs } from '../api/jobs';
import Routes from '../consts/Routes';
import { localeContext } from '../locales';
import { MODAL_TYPES, useGlobalModalContext } from '../modals/ModalContext';

const FOOTER_MENU = [
    {
        key: "MENU",
        links: [
            {
                key: "home",
                to: Routes.home
            },
            {
                key: "games",
                to: Routes.games
            },
            {
                key: "company",
                to: Routes.home
            },
            {
                key: "career",
                to: Routes.career
            },
            {
                key: "contact_us",
                to: Routes.support
            },
        ]
    },
    {
        key: "JOBS",
        links: [
        ]
    },
    // {
    //     key: "PRESS",
    //     links: [
    //         {
    //             key: "press_kit",
    //             to: Routes.press_kit
    //         },
    //     ]
    // },
    {
        key: "LEGAL",
        links: [
            {
                key: "privacy_policy",
                to: Routes.privacy
            },
            {
                key: "terms_of_use",
                to: Routes.terms
            },
        ]
    },
    {
        key: "CONTACT",
        links: [
            {
                key: "support",
                to: Routes.support
            },
            {
                key: "submit_game",
                to: Routes.submit
            },
        ]
    },
]


const moreJobs = {
    key: "view_more",
    clases: " highlighted",
    to: Routes.career
}

const socialLinks = [

    {
        icon: "icon-facebook",
        to: Routes.facebook,
    },

    {
        icon: "icon-linkedin",
        to: Routes.linkedin,
    },

    {
        icon: "icon-instagram",
        to: Routes.instagram,
    }

];


const FooterMenuLarge = ({texts, data}) => <>
    <div className='footer-row d-none d-lg-flex'>
        {
            data.map(item => 
                (
                    item.links.length ? 
                    <div key={item.key} onClick={item.onClick ? item.onClick : null} id={item.key} className='footer-col'>
                        <div className='title font-header'>{texts[item.key]}</div>
                        {
                            item.links.map((subitem, i) => 
                                <div key={item.key + "_" + i} className={"link" + (subitem.clases ? subitem.clases: "")}>
                                    {
                                        subitem.to.startsWith("http") ?
                                        <a onClick={e => e.stopPropagation()} href={subitem.to} target="_blank" rel="noreferrer">
                                            {subitem.key ? texts[subitem.key] : subitem.name}
                                        </a>
                                        :
                                        <Link to={subitem.to}>
                                            {subitem.key ? texts[subitem.key] : subitem.name}
                                        </Link>
                                    }
                                </div>
                            )
                        }
                    </div>
                    : null
            )
            )
        }
    </div>
</>;

const FooterMenuSmall = ({texts, data}) => {

    const btns = useRef(new Array(data.length));
    
    return (
        <div className='d-lg-none accordion' id="footer-menu">
            {
                data.map((item,indx) => 
                    (
                        item.links.length ? 
                        <div key={item.key} className='accordion-item'>
                            <div 
                                className='accordion-header font-header' 
                                id={"header_" + item.key}
                            >
                                <button
                                    ref={(el) => {btns.current[indx] = el}}
                                    className="accordion-button collapsed" 
                                    aria-expanded="false" 
                                    type="button" 
                                    data-bs-toggle="collapse"
                                    data-bs-target={"#" + item.key} 
                                    aria-controls={item.key}
                                >
                                        {texts[item.key]}
                                </button>
                            </div>
                            <div 
                                id={item.key} 
                                className='accordion-collapse collapse' 
                                aria-labelledby={"header_" + item.key} 
                                data-bs-parent="#footer-menu"
                                onClick={item.onClick ? item.onClick : null}
                            >
                                <div className='accordion-body'>
                                    {
                                        item.links.map((subitem, i) => 
                                            <div key={item.key + "_" + i} className={"link" + (subitem.clases ? subitem.clases: "")}>
                                                {
                                                    subitem.to.startsWith("http") ?
                                                    <a onClick={e => e.stopPropagation()} href={subitem.to} target="_blank" rel="noreferrer">
                                                        {subitem.key ? texts[subitem.key] : subitem.name}
                                                    </a>
                                                    :
                                                    <Link onClick={() => btns.current[indx].click()} to={subitem.to}>
                                                        {subitem.key ? texts[subitem.key] : subitem.name}
                                                    </Link>
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        : null
                    )
                )
            }
        </div>
    )
};

const Footer = props => {


    const [jobs, setJobs] = useState([]);

    const history = useHistory();

    const { showModal } =useGlobalModalContext();

    useEffect(() => {
        getJobs().then((data) => {
            setJobs(data)
        }).catch(err => console.log(err))

    }, [])


    if (jobs.length) {
        const jobItem = FOOTER_MENU.find(item => item.key === "JOBS");

        jobItem.onClick = (e) => {
            history.push(moreJobs.to);
        }

        jobItem.links = jobs.map((item) => ({
            ...item,
            name: item.name.replaceAll(/^Jr\.\s|^Junior\s|^Md\.\s|^Middle\s|^Lead\s|^Ld\.\s|^Senior\s|^Sr\.\s|\(\S+\)$/g, "")
        }))

        var unique = {};

        jobItem.links = jobItem.links.filter(function(item) {
            const val = item.name;
            return unique.hasOwnProperty(val) ? false : (unique[val] = true);
        })

        jobItem.links = jobItem.links.slice(0, Math.min(4, jobItem.links.length))

        jobItem.links.push(moreJobs)
    }

    const texts = useContext(localeContext);

    return <>
        <footer>
            <div className='footer-border'></div>
            <div className='footer-menu'>
                <FooterMenuLarge texts={texts} data={FOOTER_MENU}/>
                <FooterMenuSmall texts={texts} data={FOOTER_MENU}/>
                <div className='footer-socials'>
                    {
                        socialLinks.map(link => (

                            <a key={link.icon} href={link.to} rel="noreferrer" target="_blank">
                                <i className={'icon ' + link.icon}/>
                            </a>
                        ))
                    }
                </div>    
            </div>
            <div className="sticky-footer footer-bar">
                <div className="copyright font-header">
                    © 2022 GREEN PANDA GAMES. ALL RIGHTS RESERVED.
                </div>
                <div className="cookies">
                    <div className="link" onClick={() => showModal(MODAL_TYPES.COOKIE_MODAL)}>
                        Cookie settings
                    </div>

                    <div className="link" onClick={() => showModal(MODAL_TYPES.COOKIE_MODAL, {settings: true})}>
                        Do not sell my personal information
                    </div>
                </div>
            </div>
        </footer>

        
    </>
};

export default Footer;