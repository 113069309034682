import { useContext, useEffect, useState, useLayoutEffect } from "react";
import { getJobs } from "../api/jobs";
import { localeContext } from '../locales';
import FooterBenefits from "../partials/FooterBenefits";
import { Spinner } from "../components";

const JobList = ({jobs, texts}) => {
    return <>
    <div className="title font-header">{texts.career_open}</div>

        <div className="row-position row-header font-header">
            <div className="col-title">
                {texts.career_JOBTITLE}
            </div>
            <div className="col-localisation">
                {texts.career_LOCALISATION}
            </div>
        </div>

        {
            jobs.map((data, i) => (
                <div key={data.id} className="row-position">
                    <div className="col-title font-header">
                        <div className="job-title">
                            {data.name}
                        </div>
                        <div className="job-more">
                            <a href={data.to} target="_blank" rel="noreferrer">
                                {texts.career_MORE}
                            </a>
                        </div>
                    </div>
                    <div className="col-localisation">
                        {data.city}
                    </div>
                </div>
            ))
        }
</>
}

const Career = () => {

    const texts = useContext(localeContext);

    const [jobs, setJobs] = useState([]);

    const [isLoading, setLoading] = useState(false);


    useLayoutEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "instant"
        })
    });

    useEffect(() => {
        let mounted = true;

        setLoading(true);

        getJobs().then((data) => {
            if (mounted) {
                setJobs(data)
                setLoading(false);
            }
        }).catch(err => {
            setLoading(false);
        })

        return () => {
            mounted = false
        }
    }, [])

    return <div className="career-page">

        <div className="header">
            <div className="title font-header">{texts.career_title}</div>
            <div className="subtitle">{texts.career_subtitle}</div>
            <a href="#benefits">
                <div className="btn font-header">{texts.career_benefits_btn}</div>
            </a>
        </div>



        <div className="positions">
            {
                isLoading 
                ?
                <Spinner/>
                :
                (
                    jobs.length === 0
                    ?
                    <div className="d-flex justify-content-center p-5 m-5">
                        <div className="title font-header">{texts.career_nojobs}</div>
                    </div>
                    :
                    <JobList jobs={jobs} texts={texts}/>
                )
            }

        </div>
        
        <FooterBenefits/>
    </div>
}

export default Career;