/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2022 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useEffect, useRef, useState } from 'react';
// style="border-radius: 120px;"
// style="display: flex;height: 100%;align-items: center;margin: 10px;"

const noop = function(){};

function classnames(...args) 
{
    return args.filter(e => e).join(' ');
}

const Modal = (props) => 
{
    // const { modal, onHide } = props;
    const [modal, setModal] = useState(null);

    let _modal = useRef(null);

    useEffect(() => 
    {
        let cb = props.onHide || noop;

        const modal = _modal.current;

        modal.addEventListener('hidden.bs.modal', cb);

        return () => 
        {
            modal.removeEventListener('hidden.bs.modal', cb);
        }

    }, [props.onHide]);

    useEffect(() => 
    {
        if (modal) 
        {
            if (props.show) 
            {
                modal.show();
            }
            else 
            {
                modal.hide();
            }
        }
    }, [props.show, modal]);

    useEffect(() => 
    {
        const modalB = new window.bootstrap.Modal(_modal.current);

        setModal(modalB);
    }, [])

    // if (modal)
    // {
    //     const modalRef = {
    //         hide: function(cb) 
    //         {
    //             $(_modal.current).on('hidden.bs.modal', cb || noop);
    //             $(_modal.current).modal('hide');
    //         }
    //     }

    //     if (typeof modal === 'function') 
    //     {
    //         modal(modalRef);
    //     }
    //     else if (typeof modal === 'object') 
    //     {
    //         Object.assign(modal, modalRef);   
    //     }
    // }

    return (
        <>
            <div className="modal fade" data-bs-backdrop={props.backdrop || "true"} ref={_modal}>
                <div className={"modal-dialog modal-dialog-centered modal-" + (props.size || "lg")}>
                    <div className={classnames("modal-content", props.className)}>
                    
                        {
                            props.header || <div className={classnames("modal-header", props.modalHeaderClassName)}>
                                <h5 className="modal-title">{props.title || "Modal"}</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                        }

                        { 
                            props.children 
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export { Modal };