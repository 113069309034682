
// import underscore from 'underscore';
// import ObjectUtils from './ObjectUtils';
// import Popup from './Popup';
// import Confirm from './Confirm';
// import Promt from './Promt';
// import Alert from './Alert';
import Utils from './Utils';
import Hint from './Hint';


const utils = {};
Object.assign(utils, Utils);

Object.assign(utils, {
	// obj: ObjectUtils,
	// popup: Popup,
	hint: Hint,
	// confirm: Confirm,
	// promt: Promt,
	// alert: Alert,

	// Other utilities
});

export default utils;