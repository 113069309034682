/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2022 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useEffect } from 'react';
import { getLegal } from '../api/legal';

const notFound = `
<div class="text-center my-5">
    <h1 class="d-1 mb-4 text-danger">404 Not Found</h1>
    <h5 class="text-muted">Try another URL</h5>
</div>
`

const Legal = (props) => {
        
    const [content, setContent] = useState(null);

    const page = props.match.params.page;
    let langMathRes = (new RegExp("/(.*?)/")).exec(props.match.url);

    const lang = langMathRes && langMathRes[1];

    useEffect(() => {

        getLegal(page, lang).then(setContent).catch(() => setContent(notFound));

    }, [page, lang]);

    return (
        <div className='legal-page py-5'>
            { content && <div className="container px-4" id="legal-content" dangerouslySetInnerHTML={{__html: content}} /> }
            {!content && <div className="d-flex justify-content-center">
                <div className="spinner-border text-secondary" style={{"width": "100px", "height": "100px", "borderWidth": "10px"}} />
            </div>
            }
        
        </div>
    );
}

export default Legal;