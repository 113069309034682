/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useContext, useLayoutEffect } from 'react';
import { localeContext } from '../locales';



import passionImg from '../img/home/passion.webp';
import expertiseImg from '../img/home/expertise.png';
import valuesImg from '../img/home/values.png';


import FooterCareers from '../partials/FooterCareers';
import GAMES from '../consts/Games';
import { GameCard } from '../components';
import Routes from '../consts/Routes';
import { Link } from 'react-router-dom';

const FEATURED_GAMES = [
	 "game_ai",
	 "game_bf",
	 "game_sb",
	 "game_mi"
]

const STATS = [
	{
		icon: "user",
		value: "250M+",
		subtitle: "stats_users",
	},
	{
		icon: "review",
		value: "500K+",
		subtitle: "stats_reviews",
	},
	{
		icon: "expert",
		value: "+100",
		subtitle: "stats_experts",
	}
]



const StatView = (props) => {

	const { value, icon, subtitle } = props;

	return (
		<>
			<div className='d-flex flex-column stat'>
				<div className={'icon ' + icon}/>
				<div className='title font-header'>{value}</div>
				<div className='subtitle'>{subtitle}</div>
			</div>
		</>
	)
}


const Home = (props) => {

	const texts = useContext(localeContext);

	const gamesByKey = GAMES.reduce((prev, val) => {
		prev[val.key] = val;
		return prev;
	}, {})

	const gamesData = FEATURED_GAMES.map(key => gamesByKey[key]);

    useLayoutEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "instant"
        })
    });

	return (
	    <div className='home-page'>

			<div className='home-header d-flex flex-column'>

				<div className='mx-auto d-flex flex-column'>
					
					<div className='title font-header text-center mx-auto'>{texts.home_title}</div>
					<div className='subtitle'>{texts.home_subtitle}</div>
					<Link to={Routes.career} className='btn font-header mx-auto'>{texts.JOIN_US}</Link>
				</div>
			</div>
			<div className='home-content'>

			<div className='home-games d-flex flex-column'>
				<div className='title font-header text-center mx-auto'>{texts.home_games}</div>
				<div className='featured-games d-flex flex-wrap'>
					{
						gamesData.map(gameData =>

							<div key={gameData.key} className='col-6 col-md-3 col-lg-3 d-flex justify-content-center game-card'>
								<GameCard 
									title={texts[gameData.key]} 
									image={gameData.thumbnail}
									appstore_link={gameData.appstore}
									playmarket_link={gameData.playmarket}
									subtitle={texts.game_available}
								/>
							</div>
						)
					}

				</div>
			</div>

			<div className='home-stats d-flex justify-content-between flex-wrap'>
				{
					STATS.map(stat => 
						<div key={stat.icon}  className='col-12 col-md-4 col-lg-4 d-flex justify-content-center  stat-card'>
							
							<StatView
								value={stat.value}
								icon={"icon-" + stat.icon}
								subtitle={texts[stat.subtitle]}
							/>

						</div>
					)
				}
			</div>

			<div className='home-border'><div></div></div>

			<div className='home-about'>
				<div className='about-container'>
					<div className='title font-header text-center'>{texts.about_title}</div>

					<div className='d-flex flex-wrap row-passion'>

						<div className='col-12 col-lg-6 d-flex flex-column'>
							<img src={passionImg} height={600} width={600} alt="passion"></img>
						</div>

						<div className='col-12 col-lg-6 d-flex flex-column'>
							<div className='text-card text-card-left'>
								<div className='title font-header'>{texts.about_passion_title}</div>
								<div className='text'>{texts.about_passion_text}</div>
							</div>
						</div>

					</div>
					
					<div className='d-flex flex-wrap row-expertise'>
						<div className='col-12 col-lg-6 d-flex flex-column'>
							<div className='text-card text-card-right'>
								<div className='title font-header'>{texts.about_expertise_title}</div>
								<div className='text'>{texts.about_expertise_text}</div>
							</div>
						</div>
						<div className='col-12 col-lg-6 d-flex flex-column'>
							<img src={expertiseImg} height={600} width={600} alt="expertise"></img>
						</div>

					</div>

					<div className='home-values'>
						<div className='title font-header'>{texts.values_title}</div>

						<div className='d-flex flex-wrap values-row justify-content-between'>
							<div className='col-12 col-lg-6 value-card'>
								<div className='font-header title'>{texts.values_progress_title}</div>
								<div className='text'>{texts.values_progress_text}</div>
							</div>
							<div className='col-12 col-lg-6 value-card'>
								<div className='font-header title'>{texts.values_ambition_title}</div>
								<div className='text'>{texts.values_ambition_text}</div>
							</div>
							<div className='col-12 col-lg-6 value-card'>
								<div className='font-header title'>{texts.values_teamwork_title}</div>
								<div className='text'>{texts.values_teamwork_text}</div>
							</div>
							<div className='col-12 col-lg-6 value-card'>
								<div className='font-header title'>{texts.values_humility_title}</div>
								<div className='text'>{texts.values_humility_text}</div>
							</div>
						</div>
						<div className='values-img'>
							<img src={valuesImg} height={500} width={500} alt=""></img>
						</div>

					</div>
				</div>

			</div>
			</div>
			<FooterCareers/>
	    </div>
	);

}

export default Home;