const texts = {
    COMPANY: 'COMPANY',
    OUR_GAMES: 'OUR GAMES',
    SUPPORT: 'SUPPORT',
    SUBMIT_YOUR_GAME: 'SUBMIT YOUR GAME',
    JOIN_US: 'JOIN US',

    home_title: 'We make awesome idle games',
    home_subtitle: 'A fast-growing mobile games publisher specialized in idle and casual games',
    home_games: 'Featured Games',

    career_title: 'Become a game changer',
    career_subtitle:
        'If you are motivated, ambitious, and passionate about creating unique game experiences, apply now and embark in a great journey with like-minded individuals in a caring and dynamic company.',
    career_benefits_btn: 'See our benefits',

    career_open: 'Open positions',
    career_JOBTITLE: 'JOB TITLE',
    career_LOCALISATION: 'LOCALISATION',
    career_MORE: 'Learn more',
    career_nojobs: 'Sorry, there are no jobs available right now.',

    support_title: 'Player support',
    support_subtitle: (
        <>
            <p>A question? Perhaps even a suggestion? We'd be happy to hear from you,</p>
            <p>and we'll get back to you with an answer as soon as we can!</p>
        </>
    ),
    support_page_title: 'Contact us',

    submit_title: 'Want to work with us?',
    submit_subtitle: (
        <>
            <p>Want to turn your game into a hit?</p>
            <p>Reach out to us!</p>
        </>
    ),
    submit_page_title: 'Submit your game',

    games_title: 'Games',
    games_subtitle: (
        <>
            <p>Our teams are dedicated to craft unforgettable experience to players.</p>
            <p>Here are our available games</p>
        </>
    ),
    games_page_title: 'Our Games',
    games_more: 'View more',

    game_ai: 'Airplane Inc.',
    game_mi: 'Mining Inc.',
    game_bf: 'Bee Factory',
    game_sb: 'Sushi Bar',
    game_ii: 'Island Inc.',
    game_ih: 'Idle Human',
    game_ic: 'Idle Construction',
    game_9m: '9 Months',
    game_tr: 'Terrarium',
    game_available: 'Available on',

    stats_users: 'users',
    stats_reviews: '5 stars reviews',
    stats_experts: 'mobile experts',

    about_title: 'Green Panda Life',

    about_passion_title: 'Passion',
    about_passion_text: (
        <>
            <p>
                Our mission is to create <span className="text-highlighted">fun</span> and{' '}
                <span className="text-highlighted">engaging</span> gaming experiences on mobile.
            </p>
            <p>
                We are fueled by our shared <span className="text-highlighted">passion</span> for video games
                and we take great pride in the work we do.
            </p>
        </>
    ),
    about_expertise_title: 'Expertise',
    about_expertise_text: (
        <>
            <p>
                Many of our games have been <span className="text-highlighted">instant hits</span>, such as
                Bee Factory, Sushi Bar, Terrarium and more recently Mining Inc.
            </p>
            <p>
                While our passion drives our work, our sharp editorial vision is what stands us apart in the
                idle games segment. Add to that our data-driven iterative{' '}
                <span className="text-highlighted">creative process</span>, our undisputed{' '}
                <span className="text-highlighted">expertise</span> in User Acquisition and Monetisation, we
                have a real <span className="text-highlighted">winning formula!</span>
            </p>
        </>
    ),

    values_title: 'Our values',
    values_progress_title: 'Progress',
    values_progress_text: (
        <>
            <p>It’s ok to fail.</p>
            <p>Our goal is to learn and to always try to improve. We do not settle for the status quo.</p>
        </>
    ),

    values_ambition_title: 'Ambition',
    values_ambition_text: (
        <>
            <p>We are ambitious in everything we do.</p>
            <p>We always want to over-perform and we have high standards for ourselves.</p>
        </>
    ),

    values_teamwork_title: 'Team work',
    values_teamwork_text:
        'We build healthy relationships and mutual growth through cooperation, knowledge-sharing and cross-teams collaboration.',

    values_humility_title: 'Humility',
    values_humility_text: (
        <>
            <p>
                As we accept failures, we know successes are the result of a learning process and team's work.
            </p>
            <p>There is no individual hero.</p>
        </>
    ),

    footer_adventure_title: 'Take part in the adventure!',
    footer_adventure_subtitle: (
        <>
            We’re always looking for new <span className="text-highlighted">talents</span> to come and join
            us.
        </>
    ),
    footer_adventure_button: 'EXPLORE CAREERS',

    benefits_title: 'Our Benefits',
    benefits_grow_title: 'Growing',
    benefits_grow_text: 'We are a fast growing company made up of people from all around the world!',
    benefits_progress_title: 'Progress',
    benefits_progress_text: 'Part of the big Ubisoft family, we offer tremendous career opportunities!',
    benefits_company_title: 'Company events',
    benefits_company_text: 'Summer party, Christmas party, game nights, board games,...',
    benefits_sports_title: 'Keep in shape',
    benefits_sports_text: 'We love games but also sports: basketball, football, fitness, yoga,...',
    benefits_others_title: 'And many others',
    benefits_others_text: 'Free coffee, fresh fruits, and snacks, private health care, meal vouchers,...',

    contact_form_game: 'GAME',
    contact_form_game_placeholder: 'CHOOSE A GAME',
    contact_form_platform: 'PLATFORM',
    contact_form_platform_placeholder: 'CHOOSE OS',
    contact_form_version: 'GAME VERSION',
    contact_form_version_placeholder: '1.0',
    contact_form_name: 'NAME',
    contact_form_name_placeholder: 'John Doe',
    contact_form_email: 'EMAIL',
    contact_form_email_placeholder: 'name@societe.com',
    contact_form_message: 'MESSAGE',
    contact_form_message_placeholder: 'Tell us more about...',
    contact_form_send: 'SEND',

    submit_form_message_placeholder: 'Tell us more about your game...',
    submit_form_gameurl: 'GAME URL',
    submit_form_gameurl_placeholder: 'Store link',
    submit_form_company: 'COMPANY',
    submit_form_company_placeholder: 'Company name',
    submit_form_skype: 'SKYPE',
    submit_form_skype_placeholder: '@societe',

    submit_verify: 'Please verify that you are human',
    submit_ty: 'Thank you!',
    submit_submitted: 'Your form was successfully submitted',

    form_optional: '(optional)',

    MENU: 'MENU',

    company: 'Company',
    games: 'Games',
    career: 'Career',
    contact_us: 'Contact us',
    home: 'Home',

    JOBS: 'JOBS',
    view_more: 'View more',

    PRESS: 'PRESS',
    press_kit: 'Press kit',

    LEGAL: 'LEGAL',
    privacy_policy: 'Privacy Policy',
    terms_of_use: 'Terms of Use',

    CONTACT: 'CONTACT',
    support: 'Support',
    submit_game: 'Submit your game'
};

export default texts;
