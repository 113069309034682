/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2022 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import axios from 'axios';

const getLegal = async (page, lang = "en") => 
{
    let content = null;

    try 
    {
        content = await axios.get(`/legal-pages/${lang}/${page}.html`);

        if (content.startsWith("<!DOCTYPE html>") || content.startsWith("<!doctype html>")) 
        {
            content = null;
        }
    }
    catch (err) { }

    if (content === null && lang !== "en") 
    {
        content = await axios.get(`/legal-pages/en/${page}.html`);

        if (content.startsWith("<!DOCTYPE html>") || content.startsWith("<!doctype html>")) 
        {
            content = null;
        }
    }

    if (content === null) throw new Error("Not Found");

    return content;
}

export { getLegal };