import { useState } from 'react';
import { getCookies, setCookies } from '../api/cookies';
import { useGlobalModalContext } from './ModalContext';

const Preview = ({ onAccept, onDecline, onSettings }) => (
    <>
        <div className="modal-body">
            <div className="title font-header">Welcome!</div>
            <div className="text">
                <p>
                    We use cookies and technological tools to analyse the traffic of this website, enhance
                    your experience.
                </p>
                <p>
                    Maintain control over your data by setting cookies, you can change or withdraw your
                    consent at any time. If you refuse cookies, it does not affect the way our site operates,
                    however you will not be able to take full advantage of all our features.
                </p>
            </div>
        </div>

        <div className="d-flex justify-content-between cookie-btns">
            <div className="btn font-header cookies-btn decline" onClick={onDecline}>
                Decline cookies
            </div>

            <div className="btn font-header cookies-btn settings" onClick={onSettings}>
                Settings
            </div>
            <div className="btn font-header cookies-btn accept" onClick={onAccept}>
                Accept cookies
            </div>
        </div>
    </>
);

const COOKIES_DATA = [
    {
        title: 'Essential cookies',
        description:
            'These cookies are strictly necessary for the site to work and may not be disabled. (Always enabled)',
        cookies: [
            {
                name: '__cfduid',
                description: 'Used for identifying individual visitors privately.'
            },
            {
                name: 'accept_cookie_policy',
                description: 'This stores a Boolean (true/false) when user accepts the cookie policy.'
            },
            // {
            //     name: 'cookie_analytics_track',
            //     description:
            //         'This stores a Boolean (true/false) if you have accepted the use of cookies on the website for analytics purpose.'
            // }
        ],
        disabled: true,
        value: true,
        id: 'accept_cookie_policy'
    },
    // {
    //     title: 'Analytics Cookies',
    //     description: 'Do you accept the use of cookies to measure the traffic to our websites?',
    //     cookies: [
    //         {
    //             name: '_ga',
    //             description: 'Used to distinguish users.'
    //         },
    //         {
    //             name: '_gid',
    //             description: 'Used to distinguish users.'
    //         },
    //         {
    //             name: '_gat',
    //             description: 'Used to throttle request rate.'
    //         }
    //     ],
    //     value: false,
    //     id: 'cookie_analytics_track'
    // }
];

const CookieSetting = ({ title, description, cookies, value, onChange, disabled }) => {
    const id = title.toLowerCase().replace(' ', '_');

    return (
        <div className="d-flex flex-column cookie-item">
            <div className="d-flex justify-content-between">
                <div className="subtitle font-header">{title}</div>
                <div className="form-check form-switch">
                    <input
                        className="form-check-input"
                        defaultChecked={value}
                        onChange={onChange}
                        disabled={disabled === true}
                        type="checkbox"
                        role="switch"
                        id=""
                    />
                </div>
            </div>
            <div className="text">{description}</div>
            <div
                className="btn btn-dropdown mx-auto collapsed"
                data-bs-toggle="collapse"
                data-bs-target={'#' + id}
                aria-expanded="false"
                aria-controls={id}
            >
                <i />
            </div>
            <div className="collapse" id={id}>
                <div className="cookie-description card card-body d-flex flex-column">
                    {cookies.map((item) => (
                        <div key={item.name} className="cookie-row d-flex">
                            <div className="w-50">{item.name}</div>
                            <div className="w-50">{item.description}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

const defaultData = Object.assign({}, ...COOKIES_DATA.map((item) => ({ [item.id]: item.value })));

const Settings = ({ onBack, onSave }) => {
    const [data, setData] = useState(Object.assign(defaultData, getCookies()));

    const onCookieChange = (name, val) => {
        const newData = { ...data };

        newData[name] = val === true;

        setData(newData);
    };

    return (
        <>
            <div className="modal-body cookie-settings">
                <div className="title font-header">Cookie settings</div>
                {COOKIES_DATA.map((item, i) => (
                    <CookieSetting
                        key={i}
                        {...item}
                        value={data[item.id]}
                        onChange={(e) => {
                            onCookieChange(item.id, e.target.checked);
                        }}
                    />
                ))}
            </div>

            <div className="d-flex justify-content-end">
                <div className="btn cookies-btn font-header settings me-3" onClick={onBack}>
                    Back
                </div>
                <div className="btn cookies-btn font-header accept" onClick={() => onSave(data)}>
                    Save
                </div>
            </div>
        </>
    );
};

export const CookiesModal = ({ settings }) => {
    const [inSettings, setSettings] = useState(settings);

    const { hideModal } = useGlobalModalContext();

    const handleAccept = (e) => {
        setCookies({
            accept_cookie_policy: true,
            // cookie_analytics_track: true
        });
        hideModal();
    };

    const handleSave = (data) => {
        // console.log(data);
        setCookies(data);
        hideModal();
    };

    const handleDecline = (e) => {
        setCookies({
            accept_cookie_policy: true,
            // cookie_analytics_track: false
        });
        hideModal();
    };

    const goToSettings = () => {
        setSettings(true);
    };

    const goToPreview = () => {
        setSettings(false);
    };

    return (
        <>
            <div
                className="modal-dialog modal-dialog-centered cookies-modal modal-lg"
                onClick={(e) => e.stopPropagation()}
            >
                <div className="modal-content">
                    {inSettings ? (
                        <Settings onBack={goToPreview} onSave={handleSave} />
                    ) : (
                        <Preview
                            onAccept={handleAccept}
                            onDecline={handleDecline}
                            onSettings={goToSettings}
                        />
                    )}
                </div>
            </div>
        </>
    );
};
