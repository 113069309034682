/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useContext, useLayoutEffect, useRef, useState } from 'react';
import { useForm } from '../hooks/useForm';
import { localeContext } from '../locales';
import FooterCareers from '../partials/FooterCareers';
import { Spinner, CaptchaForm, FormSubmitted } from '../components';

import { sendPublishingData } from '../api/post';
import utils from '../utils';

const OptinalLabel = ({text}) => <span className='label-optional'>{text}</span>


const SubmitForm = ({texts, onSubmit}) => {

    const sanitizeInput = (val) => val === "" ? null : val;

    const { handleSubmit, handleChange, data, errors } = useForm({
        validations: {
          name: {
            required: {
                value: true,
                message: "Please enter a name."
            },
          },
          email: {
            required: {
                value: true,
                message: "Please enter email."
            },
            pattern: {
                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message:
                  "Please enter a valid email.",
            },
          },
          message: {
            required: {
                value: true,
                message: "Please enter a message."
            }
          }

        },
        onSubmit: () => onSubmit(data),
    });


    const isInputValid = (key) => errors[key] ? " is-invalid": ""
    
    return (<>
        <div className='title font-header'>
            {texts.submit_page_title}
        </div>
        <form onSubmit={handleSubmit}>
            <div className='d-flex flex-column'>
                <div className='d-flex flex-wrap form-row'>
                    <div className='col-12 col-lg-6 form-input-left'>
                        <label htmlFor="name" className="form-label">{texts.contact_form_name}</label>
                        <input type="text"  value={data.name || ""} className={"form-control" + isInputValid("name")} onChange={handleChange("name", sanitizeInput)}  id="name" placeholder={texts.contact_form_name_placeholder}/>
                        {errors.name ? <div className='invalid-feedback'>{errors.name}</div> : null}
                    </div>
                </div>
                <div className='d-flex flex-wrap'>
                    <div className='col-12 col-lg-6 form-row form-input-left'>
                        <label htmlFor="company" className="form-label">{texts.submit_form_company}<OptinalLabel text={texts.form_optional}/></label>
                        <input type="text"  value={data.company || ""} className={"form-control" + isInputValid("company")} onChange={handleChange("company", sanitizeInput)}  id="company" placeholder={texts.submit_form_company_placeholder}/>
                    </div>
                    <div className='col-12 col-lg-6 form-row form-input-right'>
                        <label htmlFor="game_url" className="form-label">{texts.submit_form_gameurl}<OptinalLabel text={texts.form_optional}/></label>
                        <input type="text"  value={data.game_url || ""} className={"form-control" + isInputValid("game_url")} onChange={handleChange("game_url", sanitizeInput)}  id="game_url" placeholder={texts.submit_form_gameurl_placeholder}/>
                    </div>
                </div>
                <div className='d-flex flex-wrap'>
                    <div className='col-12 col-lg-6 form-row form-input-left'>
                        <label htmlFor="email" className="form-label">{texts.contact_form_email}</label>
                        <input type="text"  value={data.email || ""} className={"form-control" + isInputValid("email")} onChange={handleChange("email", sanitizeInput)}  id="email" placeholder={texts.contact_form_email_placeholder}/>
                        {errors.email ? <div className='invalid-feedback'>{errors.email}</div> : null}

                    </div>
                    <div className='col-12 col-lg-6 form-row form-input-right'>
                        <label htmlFor="skype" className="form-label">{texts.submit_form_skype}<OptinalLabel text={texts.form_optional}/></label>
                        <input type="text"  value={data.skype || ""} className={"form-control" + isInputValid("skype")} onChange={handleChange("skype", sanitizeInput)}  id="skype" placeholder={texts.submit_form_skype_placeholder}/>
                    </div>
                </div>
                <div className='form-row'>
                    <label htmlFor="message" className="form-label">{texts.contact_form_message}</label>
                    <textarea  value={data.message || ""} className={"form-control" + isInputValid("message")} onChange={handleChange("message", sanitizeInput)}  id="message" placeholder={texts.submit_form_message_placeholder}/>
                    {errors.message ? <div className='invalid-feedback'>{errors.message}</div> : null}

                </div>
                <button type="submit" className='btn form-btn'>{texts.contact_form_send}</button>
            </div>
        </form>
    </>)
}

const Submit = (props) => {
 
    const texts = useContext(localeContext);


    const ref = useRef();

    const [data, setData] = useState({});

    const [step, setStep] = useState("fill") // fill captcha sending ok;


    const onFormReady = (data) => {
        setData(data);
        setStep("captcha")
    }

    const onVerify = (token) => {
 
        setStep("sending");

        sendPublishingData(data, token).then(res => {
            setStep("ok");
        })
        .catch(err => {
            utils.hint("error");
            setStep("fill")
        })

    }


    const stepComponentMap = {
        "fill": <SubmitForm texts={texts} onSubmit={onFormReady}/>,
        "captcha": <CaptchaForm texts={texts} onSubmit={onVerify}/>,
        "sending": <Spinner/>,
        "ok": <FormSubmitted texts={texts}/>
    }



 
    useLayoutEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "instant"
        })
    }, []);

    useLayoutEffect(() => {
        if (step !== "fill" && ref && ref.current)
            ref.current.scrollIntoView(false);
    }, [step]);


    return (
         <div className='submit-page'>
             <div className='submit-header page-header d-flex flex-column'>
                 <div className='mx-auto d-flex flex-column'>
                     <div className='title font-header text-center mx-auto'>{texts.submit_title}</div>
                     <div className='subtitle'>{texts.submit_subtitle}</div>
                 </div>
             </div>
             <div className='form-body'>
                <div className='form-container' ref={ref}>
                    {stepComponentMap[step]}
                </div>
             </div>
             <FooterCareers/>
         </div>
     );
 
 }
 
 export default Submit;